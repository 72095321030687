export const getRegionAndLanguage = (locale: string) => {
	if (typeof Intl.Locale === 'function') {
		const { region, language } = new Intl.Locale(locale);

		return { region, language };
	}

	const [region, language] = locale.split('-');

	return { region, language };
};

export const getLocalesByRegion = (localeArray: string[]) => {
	return localeArray.reduce<Map<string, string[]>>((map, locale) => {
		const { region } = getRegionAndLanguage(locale);

		if (region) {
			map.set(region, [...(map.get(region) || []), locale]);
		}

		return map;
	}, new Map());
};

const getMainLanguage = (localeArray: string[]) => {
	if (localeArray.length === 1) {
		const { language } = getRegionAndLanguage(localeArray[0]);
		return language;
	}
};

export const getFullRegionName = (region: string, localeArray: string[]) => {
	const language = getMainLanguage(localeArray) || 'en';
	const regionNames = new Intl.DisplayNames([language], { type: 'region' });

	return regionNames.of(region);
};

export const getFullLanguageName = (language: string) => {
	const regionNames = new Intl.DisplayNames([language], { type: 'language' });
	return regionNames.of(language);
};

export const replaceDashWithUnderscore = (locale: string) => {
	return locale.replace('-', '_');
};

export const replaceUnderscoreWithDash = (locale: string) => {
	return locale.replace('_', '-');
};
