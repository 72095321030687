import { forwardRef } from 'react';
import classNames from 'classnames';

import { FormLabel } from '@components/Form/FormLabel';

import { FormField } from '@interfaces/form';

import styles from './FormTextarea.module.css';

export const FormTextarea = forwardRef<
	HTMLTextAreaElement,
	Omit<FormField, 'type' | 'validation'>
>(
	(
		{ label, name, placeholder, errors, required, onChange, onBlur, className },
		ref
	) => {
		return (
			<div
				className={classNames(className, 'form-group stack-block')}
				data-testid="FormTextarea"
			>
				{label ? (
					<FormLabel htmlFor={name} required={required} value={label} />
				) : null}

				<textarea
					className={classNames(styles.textarea, 'fluid-font-t5')}
					id={name}
					name={name}
					onBlur={onBlur}
					onChange={onChange}
					placeholder={placeholder}
					ref={ref}
					rows={5}
				/>

				{errors && errors[name] ? (
					<p className="error fluid-font-t5">{errors[name]?.message}</p>
				) : null}
			</div>
		);
	}
);
