import { ReactNode, useEffect, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

import {
	CmsNavigationDocument,
	CmsPageMetadata,
	NewsletterSignupTheme,
} from '@interfaces/common';

import { CookieNotice } from './CookieNotice';
import { Footer } from './Footer';
import { Header } from './Header';
import { Seo } from './Seo';
import { TrackingCode } from './TrackingCode';

const defaultMetadata = {
	title: '',
	description: '',
	slug: '',
	image: '',
};

interface LayoutProps {
	children?: ReactNode;
	hasNewsletterSection?: boolean;
	newsletterTheme?: NewsletterSignupTheme;
	navigation?: CmsNavigationDocument;
	metadata?: CmsPageMetadata;
	gtmContainerId: string | null;
}

export const Layout = ({
	children,
	hasNewsletterSection = true,
	newsletterTheme,
	navigation,
	metadata = defaultMetadata,
	gtmContainerId,
}: LayoutProps) => {
	const [allowCookies, setAllowCookies] = useState<boolean | null>(null);
	const router = useRouter();
	const defaultTitle = router.locale?.includes('pt-')
		? 'Fazenda Futuro'
		: 'Future Farm';

	useEffect(() => {
		const savedPreference = localStorage.getItem('allowCookies');

		if (savedPreference !== null) {
			setAllowCookies(savedPreference === 'true');
		}
	}, []);

	let { title } = metadata;
	if (title !== defaultTitle) {
		title = `${title} | ${defaultTitle}`;
	}

	return (
		<>
			<Head>
				<meta charSet="utf-8" />
				<meta content="initial-scale=1.0, width=device-width" name="viewport" />
				<title>{title}</title>
				<link
					as="font"
					crossOrigin="anonymous"
					href="/fonts/NeusaNextPro-Regular.woff2"
					rel="preload"
					type="font/woff2"
				/>
				<link
					as="font"
					crossOrigin="anonymous"
					href="/fonts/Pressio-No35.woff2"
					rel="preload"
					type="font/woff2"
				/>
				<link href="cdn.sanity.io" rel="preconnect" />
				{metadata.description ? (
					<meta content={metadata.description} name="description" />
				) : null}
			</Head>
			<Seo metadata={metadata} />
			<Header mainNavigationItems={navigation?.main} />
			<main>{children}</main>
			<Footer
				navigation={
					navigation
						? {
								footer: navigation.footer,
								social: navigation.social,
								legal: navigation.legal,
						  }
						: undefined
				}
				newsletterTheme={newsletterTheme}
				showNewsletter={hasNewsletterSection}
			/>
			{allowCookies === null ? (
				<CookieNotice setAllowCookies={setAllowCookies} />
			) : null}
			{allowCookies ? <TrackingCode gtmContainerId={gtmContainerId} /> : null}
		</>
	);
};
