import { Logo } from '@components/Logo';
import { Navigation, NavigationFullscreen } from '@components/Navigation';

import { useMediaQuery } from '@hooks/useMediaQuery';
import { CmsMainNavigationItem } from '@interfaces/common';
import { breakpoints } from '@utils/device';

import styles from './Header.module.css';

interface HeaderProps {
	mainNavigationItems?: CmsMainNavigationItem[];
}

export const Header: React.VFC<HeaderProps> = ({ mainNavigationItems }) => {
	const isBreakpointLarge = useMediaQuery(breakpoints.lg);

	const renderNavigation = () => {
		if (isBreakpointLarge) {
			return <Navigation mainNavigationItems={mainNavigationItems} />;
		}

		return <NavigationFullscreen mainNavigationItems={mainNavigationItems} />;
	};

	return (
		<header className={styles.header}>
			<Logo />

			{renderNavigation()}
		</header>
	);
};
