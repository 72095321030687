import { useEffect, useState } from 'react';

// Courtesy of Cassidy Williams and Netlify:
// https://www.netlify.com/blog/2020/12/05/building-a-custom-react-media-query-hook-for-more-responsive-apps/
export const useMediaQuery = (query: string): boolean | null => {
	const [matches, setMatches] = useState<boolean | null>(null);

	useEffect(() => {
		const media = window.matchMedia(query);

		if (media.matches !== matches) {
			setMatches(media.matches);
		}

		const listener = () => {
			setMatches(media.matches);
		};

		if (media.addEventListener) {
			media.addEventListener('change', listener);
		} else {
			media.addListener(listener);
		}

		return () => {
			if (media.removeEventListener) {
				media.removeEventListener('change', listener);
			} else {
				media.removeListener(listener);
			}
		};
	}, [matches, query]);

	return matches;
};
