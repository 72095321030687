/* eslint-disable @typescript-eslint/no-var-requires */

const PicoSanity = require('picosanity');

const config = {
	apiVersion: '2021-10-21',
	dataset: process.env.NEXT_PUBLIC_SANITY_STUDIO_DATASET || '',
	projectId: process.env.NEXT_PUBLIC_SANITY_STUDIO_PROJECT_ID || '',
	useCdn: false,
};

const sanityClient = new PicoSanity(config);

module.exports = sanityClient;
