export const getSocialIconId = (url: string) => {
	try {
		const { hostname } = new URL(url);
		const hostnameParts = hostname.split('.');

		return hostnameParts[hostnameParts.length - 2];
	} catch {
		// eslint-disable-next-line no-console
		console.warn(`missing social iconId for ${url}`);
	}

	return undefined;
};
