import { useIntl } from 'react-intl';

import { Dialog } from '@components/Dialog';
import { Navigation } from '@components/Navigation';
import { Svg } from '@components/Svg';

import { navigationMessages } from '@data/ui-messages/navigation';
import { CmsMainNavigationItem } from '@interfaces/common';

import styles from './NavigationFullscreen.module.css';

interface FullscreenNavigationProps {
	mainNavigationItems?: CmsMainNavigationItem[];
}

export const NavigationFullscreen: React.VFC<FullscreenNavigationProps> = ({
	mainNavigationItems,
}) => {
	const intl = useIntl();

	return (
		<Dialog
			classNames={{
				container: styles.container,
				dialog: styles.dialog,
				openButton: styles.openButton,
				title: 'sr-only',
			}}
			closeButtonLabel={intl.formatMessage(
				navigationMessages.closeMainNavigationLabel
			)}
			id="fullscreen-navigation-dialog"
			openButtonContent={<Svg id="hamburger" />}
			openButtonLabel={intl.formatMessage(
				navigationMessages.openMainNavigationLabel
			)}
			title={intl.formatMessage(navigationMessages.mainNavigationLabel)}
		>
			<Navigation mainNavigationItems={mainNavigationItems} />
		</Dialog>
	);
};
