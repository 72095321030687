import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import Link from 'next/link';
import classNames from 'classnames';

import { FormCheckbox } from '@components/Form/FormCheckbox';
import { FormResult } from '@components/Form/FormResult';
import { FormSubmit } from '@components/Form/FormSubmit';

import { newsletterMessages } from '@data/ui-messages/newsletter';
import { serverMessages } from '@data/ui-messages/server';
import { NewsletterSignupTheme } from '@interfaces/common';
import { postFetchOptions } from '@utils/api';
import { emailRegex } from '@utils/form';

import styles from './NewsletterSignup.module.css';

interface NewsletterSignupProps {
	theme?: NewsletterSignupTheme;
}

export const NewsletterSignup: React.VFC<NewsletterSignupProps> = ({
	theme = 'dark',
}) => {
	const [signupSuccess, setSignupSuccess] = useState(false);
	const [waitingForServer, setWaitingForServer] = useState(false);
	const intl = useIntl();

	const {
		register,
		handleSubmit,
		setError,
		reset,
		formState: { errors, isSubmitted, isValid },
	} = useForm({
		mode: 'onChange',
	});

	const onSubmit = (data: any) => {
		setWaitingForServer(true);

		fetch('/api/newsletter-signup', {
			...postFetchOptions,
			body: JSON.stringify(data),
		})
			.then((result) => result.json())
			.then((json) => {
				// When Klaviyo is successful it returns an empty array
				if (Array.isArray(json)) {
					setSignupSuccess(true);
					reset();
				} else {
					setError('server', {
						type: 'server',
						message: intl.formatMessage(serverMessages.internalServerError),
					});
				}
			})
			.catch((error) => {
				setError('server', {
					type: 'server',
					message: error.messageId
						? intl.formatMessage(
								serverMessages[error.messageId as keyof typeof serverMessages]
						  )
						: error.message,
				});
			})
			.finally(() => {
				setWaitingForServer(false);
			});
	};

	return (
		<section
			className={classNames(
				styles['newsletter-signup'],
				styles[`${theme}-theme`],
				'stack-block'
			)}
			data-testid="NewsletterSignup"
		>
			<div className={classNames(styles.title, 'stack-block')}>
				<h2 className="fluid-font-t4-bold">
					{intl.formatMessage(newsletterMessages.title)}
				</h2>
				<p className="fluid-font-t4">
					{intl.formatMessage(newsletterMessages.description)}
				</p>
			</div>

			<form
				className="stack-block"
				noValidate
				onSubmit={handleSubmit(onSubmit)}
			>
				<div className={classNames(styles['form-group'], 'stack-block')}>
					<div
						className={classNames(styles.email, {
							[styles['has-errors']]: errors.email,
						})}
					>
						<input
							aria-label={intl.formatMessage(newsletterMessages.input)}
							{...register('email', {
								required: {
									value: true,
									message: intl.formatMessage(
										newsletterMessages.emailRequiredError
									),
								},
								pattern: {
									value: emailRegex,
									message: intl.formatMessage(
										newsletterMessages.emailInvalidError
									),
								},
							})}
							autoComplete="email"
							className="fluid-font-t4"
							name="email"
							placeholder={intl.formatMessage(newsletterMessages.input)}
							type="email"
						/>

						<FormSubmit
							disabled={waitingForServer}
							isValid={isValid}
							label={intl.formatMessage(newsletterMessages.buttonLabel)}
							mobileResize="shrink"
						/>

						<span className={styles['input-underline']} />
					</div>

					{isSubmitted && errors.email ? (
						<p className={classNames(styles.error, 'fluid-font-t5')}>
							{errors.email.message}
						</p>
					) : null}
				</div>

				<input
					autoComplete="off"
					{...register('future')}
					hidden
					name="future"
					type="text"
				/>

				<FormCheckbox
					className={classNames(styles['privacy-checkbox'])}
					{...register('privacy', {
						required: intl.formatMessage(newsletterMessages.privacyPolicyError),
					})}
					errors={errors}
					label={
						<FormattedMessage
							defaultMessage={newsletterMessages.checkbox.defaultMessage}
							id={newsletterMessages.checkbox.id}
							values={{
								a: (...chunks: any) => (
									<Link href="/legal/privacy" passHref>
										<a className={styles['privacy-link']} href="-">
											{chunks}
										</a>
									</Link>
								),
							}}
						/>
					}
					name="privacy"
					required
				/>
			</form>

			<FormResult
				className={styles['form-result']}
				// HACK: message is provided as a template literal to maintain the line brake using white-space property with pre-line on smaller devices
				isOpen={signupSuccess}
				message={intl.formatMessage(newsletterMessages.success)}
			/>

			{typeof errors.server?.message === 'string' ? (
				<FormResult
					autoClose
					className={styles['form-result']}
					isOpen={Boolean(errors.server)}
					message={errors.server.message}
				/>
			) : null}
		</section>
	);
};
