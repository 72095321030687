export const languageSwitcherMessages = {
	heading: {
		id: 'languageSwitcherHeading',
		defaultMessage: 'Open language switcher',
	},
	defaultLabel: {
		id: 'languageSwitcherDefaultLabel',
		defaultMessage: 'Language Switcher',
	},
	openButtonLabel: {
		id: 'languageSwitcherOpenButtonLabel',
		defaultMessage: 'Open language switcher',
	},
	closeButtonLabel: {
		id: 'languageSwitcherCloseButtonLabel',
		defaultMessage: 'Close language switcher',
	},
};
