import { IntlShape, useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import classNames from 'classnames';

import { Dialog } from '@components/Dialog';
import { LanguageSwitcherNav } from '@components/LanguageSwitcher/';
import { Svg } from '@components/Svg';

import { languageSwitcherMessages } from '@data/ui-messages/language-switcher';
import {
	getFullLanguageName,
	getFullRegionName,
	getRegionAndLanguage,
} from '@utils/locale';

import styles from './LanguageSwitcher.module.css';

const getOpenButtonText = (locale: string, intl: IntlShape) => {
	const { language, region } = getRegionAndLanguage(locale);

	if (language && region) {
		return `${getFullRegionName(region, [locale])} / ${getFullLanguageName(
			language
		)}`;
	}

	return intl.formatMessage(languageSwitcherMessages.defaultLabel);
};

export const LanguageSwitcher: React.VFC = () => {
	const { locale, locales } = useRouter();
	const intl = useIntl();

	if (!locale || !locales) {
		return null;
	}

	const openButtonText = getOpenButtonText(locale, intl);

	return (
		<Dialog
			classNames={{
				openButton: styles.openButton,
				dialog: styles.dialog,
				title: classNames(styles.title, 'fluid-font-t6-bold'),
			}}
			closeButtonLabel={intl.formatMessage(
				languageSwitcherMessages.closeButtonLabel
			)}
			id="language-switcher-dialog"
			openButtonContent={
				<>
					<Svg aria-hidden id="globe" />
					<span>{openButtonText}</span>
				</>
			}
			openButtonLabel={intl.formatMessage(
				languageSwitcherMessages.openButtonLabel
			)}
			title={intl.formatMessage(languageSwitcherMessages.heading)}
		>
			<LanguageSwitcherNav className={styles.nav} />
		</Dialog>
	);
};
