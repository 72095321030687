import { formatISODuration } from 'date-fns';

export const currentYear = new Date().getFullYear();

interface UnitLabels {
	hours: string;
	minutes: string;
}

export const durationFromMinutes = (
	totalMinutes: number,
	unitLabels?: Partial<UnitLabels>
) => {
	const hours = totalMinutes / 60;
	const minutes = totalMinutes % 60;

	if (hours < 1) {
		return `${minutes} ${unitLabels?.minutes || 'mins.'}`;
	}

	return `${Math.round(hours * 2) / 2} ${unitLabels?.hours || 'hrs.'}`; // Note: returns hour decimals in 30 mins intervals: "1.5 hrs."
};

export const minutesToISODuration = (totalMinutes: number) => {
	const hours = Math.floor(totalMinutes / 60);
	const minutes = Math.round(totalMinutes % 60);

	return formatISODuration({ hours, minutes });
};
