import { forwardRef } from 'react';

import { FormField, FormSelectOption } from '@interfaces/form';

import { FormCheckbox } from '../FormCheckbox';
import styles from './FormCheckboxGroup.module.css';

interface FormCheckboxGroupProps
	extends Omit<FormField, 'type' | 'placeholder' | 'validation'> {
	options?: FormSelectOption[];
}

export const FormCheckboxGroup = forwardRef<
	HTMLInputElement,
	FormCheckboxGroupProps
>(({ errors, name, options = [], ...rest }, ref) => (
	<div className={styles.wrapper}>
		<ul className={styles.group}>
			{options.map((option, index) => (
				<li key={option.value}>
					<FormCheckbox
						{...rest}
						{...option}
						id={`${name}-${index}`}
						name={name}
						ref={ref}
						value={option.value}
					/>
				</li>
			))}
		</ul>
		{errors && errors[name] ? (
			<p className="error fluid-font-t5">{errors[name]?.message}</p>
		) : null}
	</div>
));
