import { sanityFetch } from '@data/sanity/client';

export const getUIMessages = async (
	locale: string | undefined
): Promise<Record<string, string>> => {
	// get UI messages for the requested locale and fall back to base
	return sanityFetch(
		`
		coalesce(
			*[_type == $type && __i18n_lang == $locale][0],
			*[_type == $type][0]
		) {
			acceptAll,
			badRequestError,
			carouselNextLabel,
			carouselPreviousLabel,
			closeMainNavigationLabel,
			closeProductNavigation,
			contactButtonLabel,
			contactSubmitSuccessDescription,
			contactSubmitSuccessTitle,
			cookieNoticeAriaLabel,
			cookieNoticeText,
			declineAll,
			footerCopyright,
			homeLinkLabel,
			internalServerError,
			languageSwitcherCloseButtonLabel,
			languageSwitcherDefaultLabel,
			languageSwitcherHeading,
			languageSwitcherOpenButtonLabel,
			learnMore,
			mainNavigationLabel,
			newsletterButtonAriaLabel,
			newsletterButtonLabel,
			newsletterCheckboxLabel,
			newsletterDescription,
			newsletterEmailInvalidError,
			newsletterEmailRequiredError,
			newsletterInputPlaceholder,
			newsletterPrivacyPolicyError,
			newsletterServerError,
			newsletterSuccessMessage,
			newsletterTitle,
			openMainNavigationLabel,
			openProductNavigation,
			productInfoDialogCloseLabel,
			productInfoDialogOpenLabel,
			productInfoDialogTitle,
			productIngredientsTitle,
			productNutritionFirstColumnTitle,
			productNutritionSecondColumnTitle,
			productNutritionTitle,
			productStorageAndHandlingTitle,
			productBuyButtonLabel,
			sellButtonLabel,
			sellSubmitSuccessTitle,
			sellSubmitSuccessDescription,
			sellSubmitSuccessDescription,
			recipesCloseFilterLabel,
			recipesOpenFilterLabel,
			recipesListLabel,
			recipesFiltersLabel,
			recipesInstructionListLabel,
			recipesFilterCriteriaListLabel,
			recipesWhereToBuy,
			recipesTagList,
			recipesIngredients,
			recipesMethod,
			recipesProductRecipes,
			recipesMoreProductRecipes,
			recipesAllProductRecipes,
			recipesServes,
			recipesPeople,
			recipesTime,
			recipesTotalTime,
			recipesDifficulty,
			recipesCategory,
			recipesCuisine,
			recipesEasy,
			recipesMedium,
			recipesHard,
			recipesLoading,
			recipesLoadMore,
			recipesShowingNumRecipes,
			recipesAbbreviatedHours,
			recipesAbbreviatedMins,
			recipesNoResults
		}
	`,
		{
			type: 'uiMessages',
			locale,
		}
	);
};
