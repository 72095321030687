import styles from './Svg.module.css';

interface SvgProps {
	id: string;
	className?: string;
}

export const Svg: React.VFC<SvgProps> = ({ id, ...props }) => {
	const href = `/svgs/sprite.svg#${id}`;

	return (
		<svg className={styles.svg} xmlns="http://www.w3.org/2000/svg" {...props}>
			<use href={href} />
		</svg>
	);
};
