import React, { forwardRef } from 'react';
import classNames from 'classnames';

import { FormLabel } from '@components/Form/FormLabel';

import { FormField } from '@interfaces/form';

import styles from './FormCheckbox.module.css';

export const FormCheckbox = forwardRef<
	HTMLInputElement,
	Omit<FormField, 'type' | 'placeholder' | 'validation'>
>(
	(
		{
			id,
			label,
			name,
			required = false,
			errors,
			className,
			onChange,
			onBlur,
			value,
		},
		ref
	) => {
		const inputId = id || name;

		return (
			<div
				className={classNames(
					className,
					styles.wrapper,
					'form-group stack-inline'
				)}
				data-testid="FormCheckbox"
			>
				<input
					className={classNames(styles.input, 'fluid-font-t5', 'checkbox')}
					id={inputId}
					name={name}
					onBlur={onBlur}
					onChange={onChange}
					ref={ref}
					type="checkbox"
					value={value}
				/>

				<div className="stack-block">
					{label ? (
						<FormLabel htmlFor={inputId} required={required} value={label} />
					) : null}

					{errors && errors[name] ? (
						<p className="error fluid-font-t5">{errors[name]?.message}</p>
					) : null}
				</div>
			</div>
		);
	}
);
