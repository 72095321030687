import { useEffect } from 'react';
import debounce from 'lodash.debounce';

import { breakpoints } from '@utils/device';

import { useMediaQuery } from './useMediaQuery';

// Hack: this fixes the viewport units on mobile issue, detailed here: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
export const useOnRotationSetVH = () => {
	const isMediaMinLarge = useMediaQuery(breakpoints.lg);

	useEffect(() => {
		if (!isMediaMinLarge) {
			let wasVertical = true;

			const setVH = debounce(() => {
				const vh = window.innerHeight * 0.01;
				document.documentElement.style.setProperty('--vh', `${vh}px`);
			}, 300);

			const onResize = () => {
				const isVertical = window.innerHeight > window.innerWidth;

				if (wasVertical === isVertical) {
					return;
				}

				wasVertical = isVertical;

				setVH();
			};

			setVH();

			window.addEventListener('resize', onResize);

			return () => {
				window.removeEventListener('resize', onResize);
			};
		}
	}, []);
};
