import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '@css/global.css';

import { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { AppProps } from 'next/dist/shared/lib/router/router';
import { useRouter } from 'next/router';
import { IntlErrorCode } from '@formatjs/intl';
import { shouldPolyfill } from '@formatjs/intl-displaynames/should-polyfill';
import { captureException } from '@sentry/nextjs';

import { Layout } from '@components/Layout';

import { getUIMessages } from '@data/sanity/queries';
import { useAxe } from '@hooks/useAxe';
import { useKeyboardFocus } from '@hooks/useKeyboardFocus';
import { useOnRotationSetVH } from '@hooks/useOnRotationSetVH';

// Hack: Suppresses the `Invalid type: 'container' must be a String or HTMLElement.` alert message from StoreRocket see the `Type Errors in Production` bug here: https://storerocket.io/app/bugs
if (typeof window !== 'undefined') {
	const nativeAlert = window.alert;
	window.alert = (arg) => {
		if (
			arg.message !==
			`Invalid type: 'container' must be a String or HTMLElement.`
		) {
			nativeAlert(arg);
		}
	};
}

// Similar to `test-utils.tsx`, but it also captures the exception with Sentry
const onIntlError = (error: any) => {
	if (error.code === IntlErrorCode.MISSING_TRANSLATION) {
		console.warn(error.message);
		return;
	}

	captureException(error);
};

const App: React.FC<AppProps> = ({ Component, pageProps }) => {
	useAxe();
	useKeyboardFocus();
	useOnRotationSetVH();

	const [checkedIntlPolyfill, setCheckedIntlPolyfill] = useState(false);
	const { locale, locales, defaultLocale } = useRouter();
	const [uiMessages, setUIMessages] = useState<Record<string, string> | null>(
		null
	);

	useEffect(() => {
		// Detect Intl.DisplayNames support and dynamically import polyfill (https://formatjs.io/docs/polyfills/intl-displaynames)
		if (!checkedIntlPolyfill) {
			(async () => {
				if (locales) {
					const languages: Record<string, Promise<void>> = {};

					for (const currentLocale of locales) {
						const polyfillLanguage = shouldPolyfill(currentLocale);

						// Note: We first check for `Intl.DisplayNames.supportedLocalesOf(locale)`, because `shouldPolyfill(locale)` does not yield expected results on Chrome
						if (
							!Intl?.DisplayNames?.supportedLocalesOf(currentLocale)?.length &&
							polyfillLanguage
						) {
							if (Object.keys(languages).length === 0) {
								// eslint-disable-next-line no-await-in-loop
								await import('@formatjs/intl-displaynames/polyfill-force');
							}

							if (!languages[polyfillLanguage]) {
								languages[polyfillLanguage] = import(
									`@formatjs/intl-displaynames/locale-data/${polyfillLanguage}`
								);
							}
						}
					}

					await Promise.all(Object.values(languages));
				}

				setCheckedIntlPolyfill(true);
			})();
		}

		const fetchUIMessages = async () => {
			const data = await getUIMessages(locale);
			setUIMessages(data);
		};
		fetchUIMessages().catch(captureException);
	}, [locale]);

	if (locale && !checkedIntlPolyfill) return null;

	return (
		<IntlProvider
			defaultLocale={defaultLocale}
			locale={locale || 'en-US'}
			messages={uiMessages || {}}
			onError={onIntlError}
		>
			<Layout
				gtmContainerId={pageProps.gtmContainerId}
				hasNewsletterSection={pageProps.hasNewsLetterSection}
				metadata={
					pageProps.metadata ? pageProps.metadata : pageProps.siteMetadata
				}
				navigation={pageProps.navigation}
				newsletterTheme={pageProps.newsletterTheme}
			>
				<Component {...pageProps} />
			</Layout>
		</IntlProvider>
	);
};

export default App;
