export const serverMessages = {
	badRequestError: {
		id: 'badRequestError',
		defaultMessage: 'Bad request.',
	},
	internalServerError: {
		id: 'internalServerError',
		defaultMessage:
			'Unfortunately there was a server error and the form was not sent. Please try again later.',
	},
};
