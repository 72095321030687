import { AboutPageLink } from '@components/AboutPageLink';

import { CmsAboutSubnavigation } from '@interfaces/common';

import styles from './AboutSubnavigation.module.css';

interface ProductSubnavigationProps
	extends Pick<CmsAboutSubnavigation, 'pages'> {
	onNavigate: () => void;
}

export const AboutSubnavigation: React.VFC<ProductSubnavigationProps> = ({
	pages,
	onNavigate,
}) => {
	return (
		<ul className={styles.list}>
			{pages.map((page) => (
				<li className={styles.item} key={page._id}>
					<AboutPageLink {...page} onNavigate={onNavigate} />
				</li>
			))}
		</ul>
	);
};
