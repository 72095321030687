export const newsletterMessages = {
	title: {
		id: 'newsletterTitle',
		defaultMessage: 'Ditch the past. Join the future!',
	},
	description: {
		id: 'newsletterDescription',
		defaultMessage:
			'Subscribe for news, eco-tips, exclusive promotions, and more...',
	},
	input: {
		id: 'newsletterInputPlaceholder',
		defaultMessage: 'Enter your email',
	},
	buttonLabel: {
		id: 'newsletterButtonLabel',
		defaultMessage: 'Sign me up',
	},
	checkbox: {
		id: 'newsletterCheckboxLabel',
		defaultMessage:
			'By subscribing, you confirm you have read and accept our <a>privacy policy</a>',
	},
	success: {
		id: 'newsletterSuccessMessage',
		defaultMessage: "Thank you and welcome! It's great to have you on board.",
	},
	emailRequiredError: {
		id: 'newsletterEmailRequiredError',
		defaultMessage: 'Please enter your email address',
	},
	emailInvalidError: {
		id: 'newsletterEmailInvalidError',
		defaultMessage: 'Please enter a valid email address',
	},
	privacyPolicyError: {
		id: 'newsletterPrivacyPolicyError',
		defaultMessage: 'Please accept the privacy policy',
	},
};
