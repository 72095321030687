import { CmsRecipePageSettings } from '@interfaces/common';

import { sanityFetch } from '../client';

let recipePageSettings: CmsRecipePageSettings;

export const getRecipePageSettings =
	async (): Promise<CmsRecipePageSettings> => {
		if (recipePageSettings) {
			return recipePageSettings;
		}

		recipePageSettings = await sanityFetch(
			`*[_type == $type][0] {
				numRecipesInCarousel,
				numRecipesToLoad
			}`,
			{
				type: 'recipePageSettings',
			}
		);

		return recipePageSettings;
	};
