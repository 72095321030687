import classNames from 'classnames';

import { Svg } from '@components/Svg';

import styles from './FormSubmit.module.css';

interface FormSubmitProps {
	label: string;
	isValid?: boolean;
	disabled?: boolean;
	mobileResize?: 'shrink' | 'grow';
	isLoading?: boolean;
}

export const FormSubmit: React.VFC<FormSubmitProps> = ({
	label,
	isValid = false,
	disabled = false,
	isLoading = false,
	mobileResize = 'grow',
}) => {
	return (
		<button
			aria-label={label}
			className={classNames(
				styles['form-submit'],
				'fluid-font-t6-bold',
				'stack-inline',
				{
					[styles.invalid]: !isValid,
					[styles.grow]: mobileResize === 'grow',
				}
			)}
			data-testid="FormSubmit"
			disabled={disabled}
			type="submit"
		>
			<span
				className={classNames({
					[styles['remove-label']]: mobileResize === 'shrink',
					[styles.hide]: isLoading,
				})}
			>
				{label}
			</span>

			<Svg
				className={classNames(styles.arrow, {
					[styles.hide]: isLoading,
				})}
				id="arrow-left"
			/>

			{isLoading ? (
				<span className={styles.smiley}>
					<Svg id="smiley" />
				</span>
			) : null}
		</button>
	);
};
