import Link from 'next/link';

export const linkMarks = {
	link: ({ children, value }: any) =>
		value.blank ? (
			<a href={value.href} rel="noopener noreferrer" target="_blank">
				{children}
			</a>
		) : (
			<a href={value.href}>{children}</a>
		),
	internalLink: ({ children, value }: any) => (
		<Link href={`/legal/${value.href}`} passHref>
			<a href="">{children}</a>
		</Link>
	),
};
