export const footerMessages = {
	copyright: {
		id: 'footerCopyright',
		defaultMessage: 'Future Farm',
	},
	navigationLabel: {
		id: 'footerNavigationLabel',
		defaultMessage: 'Footer navigation',
	},
	socialMediaLabel: {
		id: 'footerSocialMediaNavLabel',
		defaultMessage: 'Footer social media navigation',
	},
	legalNavLabel: {
		id: 'footerLegalNavLabel',
		defaultMessage: 'Footer legal pages navigation',
	},
};
