const baseFetchOptions = {
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
};

export const postFetchOptions = {
	...baseFetchOptions,
	method: 'POST',
};

export const getFetchOptions = {
	...baseFetchOptions,
	method: 'GET',
};
