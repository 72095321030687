import Head from 'next/head';
import { useRouter } from 'next/router';

import { CmsPageMetadata } from '@interfaces/common';
import { replaceDashWithUnderscore } from '@utils/locale';

interface SeoProps {
	metadata: CmsPageMetadata;
}

const renderAltLocaleTags = (locales: string[]): JSX.Element[] => {
	return locales.map((locale) => (
		<meta
			content={replaceDashWithUnderscore(locale)}
			key={locale}
			property="og:locale:alternate"
		/>
	));
};

export const Seo: React.FC<SeoProps> = ({ metadata }) => {
	const router = useRouter();
	const { title, description, image } = metadata;

	const altLocales = router.locales?.filter(
		(locale) => locale !== router.locale
	);

	return (
		<Head>
			<meta content="website" property="og:type" />
			<meta content={title} property="og:title" />
			<meta content={description} property="og:description" />
			{image ? <meta content={image} property="og:image" /> : null}
			<meta
				content={replaceDashWithUnderscore(router.locale ? router.locale : '')}
				property="og:locale"
			/>
			{altLocales ? renderAltLocaleTags(altLocales) : null}
		</Head>
	);
};
