import { defaultLocales } from 'config';

import {
	RECIPES_BY_FILTERS_QUERY,
	RECIPES_BY_PRODUCT_QUERY,
	RECIPES_FILTERS,
} from '@data/fragments/recipes';
import {
	CmsFilteredRecipes,
	CmsPath,
	CmsProductRecipesPageDocument,
	CmsRecipeListCard,
	RecipesQueryFilters,
} from '@interfaces/common';
import { replaceDashWithUnderscore } from '@utils/locale';

import { sanityFetch } from '../client';
import { getRecipePageSettings } from './recipe-page-settings';

export const getProductRecipesPage = async (
	locale: string | undefined = 'en_US',
	slug: string
): Promise<CmsProductRecipesPageDocument> => {
	const { numRecipesToLoad } = await getRecipePageSettings();

	return sanityFetch(
		`
			*[_type == $type && __i18n_lang == $locale && metadata.slug.current == $slug][0] {
				metadata {
					title,
					description,
					'slug': slug.current,
					'image': image.asset->url
				},
				product->{
					metadata {
						'slug': slug.current,
					},
					category->{
						highlightColor
					},
				},
				'recipes': ${RECIPES_BY_PRODUCT_QUERY} | order(_createdAt desc) [0...$size]
				{
					_createdAt,
					_id,
					'title': metadata.title,
					'slug': metadata.slug.current,
					image {
						_type,
						alt,
						asset,
						'width': asset->metadata.dimensions.width,
						'height': asset->metadata.dimensions.height,
						'lqip': asset->metadata.lqip
					},
					totalTime,
					difficulty,
					'subtitle': product->metadata.title,
				},
				'filters': {
					'categories': *[_type == 'recipeCategory' && __i18n_lang == $locale][]{
							title,
							'name': slug.current,
							'value': slug.current
					},
					'cuisines': *[_type == 'recipeCuisine' && __i18n_lang == $locale][]{
							title,
							'name': slug.current,
							'value': slug.current
					},
					'serves': *[defined(serves) && __i18n_lang == $locale] | order(serves asc)[].serves,
					'totalTimes': *[defined(totalTime) && __i18n_lang == $locale] | order(totalTime asc)[].totalTime
				},
				'totalRecipes': count(${RECIPES_BY_PRODUCT_QUERY})
			}
		`,
		{
			type: 'recipeProduct',
			recipePageType: 'recipePage',
			locale,
			slug,
			size: numRecipesToLoad,
		}
	);
};

export const getFilteredProductRecipes = async (
	productSlug: string,
	locale: string | undefined,
	filters: Partial<RecipesQueryFilters> | null = null
): Promise<CmsFilteredRecipes> => {
	const { numRecipesToLoad } = await getRecipePageSettings();

	return sanityFetch(
		`
		{
			'recipes': ${RECIPES_BY_FILTERS_QUERY} | order(_createdAt desc) [0...$size] {
				_createdAt,
				_id,
				'title': metadata.title,
				'slug': metadata.slug.current,
				image {
					_type,
					alt,
					asset,
					'width': asset->metadata.dimensions.width,
					'height': asset->metadata.dimensions.height,
					'lqip': asset->metadata.lqip
				},
				totalTime,
				difficulty,
				'subtitle': product->metadata.title,
			},
			'totalRecipes': count(${RECIPES_BY_FILTERS_QUERY})
		}`,
		{
			type: 'recipePage',
			productSlug,
			size: numRecipesToLoad,
			locale,
			filters,
		}
	);
};

export const getNextProductRecipes = async (
	lastCreatedAtDate: string,
	lastId: string,
	productSlug: string,
	locale: string | undefined,
	filters?: Partial<RecipesQueryFilters> | null
): Promise<Required<CmsRecipeListCard>[]> => {
	const { numRecipesToLoad } = await getRecipePageSettings();

	return sanityFetch(
		`*[
			_type == $type &&
			__i18n_lang == $locale &&
			product->metadata.slug.current == $productSlug &&
			${RECIPES_FILTERS} &&
			(_createdAt < $lastCreatedAtDate || (_createdAt < $lastCreatedAtDate && _id < $lastId))
		] | order(_createdAt desc) [0...$size]
		{
			_createdAt,
			_id,
			'title': metadata.title,
			'slug': metadata.slug.current,
			image {
				_type,
				alt,
				asset,
				'width': asset->metadata.dimensions.width,
				'height': asset->metadata.dimensions.height,
				'lqip': asset->metadata.lqip
			},
			totalTime,
			difficulty,
			'subtitle': product->metadata.title,
		}`,
		{
			type: 'recipePage',
			productSlug,
			lastCreatedAtDate,
			lastId,
			size: numRecipesToLoad,
			locale,
			filters,
		}
	);
};

export const getAllProductRecipesPageSlugs = async (): Promise<CmsPath[]> => {
	return sanityFetch(
		`
			*[_type == $type && (__i18n_lang in *[_type == 'language' && enabled == true].locale || __i18n_lang in $defaultLocales)] {
				'params': {
					'slug': metadata.slug.current
				},
				'locale': __i18n_lang
			}
		`,
		{
			type: 'recipeProduct',
			defaultLocales: defaultLocales.map((locale) =>
				replaceDashWithUnderscore(locale)
			),
		}
	);
};
