interface Breakpoints {
	xs: string;
	sm: string;
	md: string;
	lg: string;
	xl: string;
	xxl: string;
	xxxl: string;
}

// Make sure to sync with @css/custom-media.css
export const breakpoints: Breakpoints = {
	xs: '(min-width: 20em)',
	sm: '(min-width: 26.75em)',
	md: '(min-width: 48em)',
	lg: '(min-width: 64em)',
	xl: '(min-width: 80em)',
	xxl: '(min-width: 90em)',
	xxxl: '(min-width: 94.5em)',
};

export const prefersReducedMotion = '(prefers-reduced-motion: reduce)';

export const isRenderingOnServer = (): boolean => typeof window === 'undefined';

export const isDesktop = (): boolean =>
	!isRenderingOnServer() && window.matchMedia(breakpoints.lg).matches;
