export const RECIPES_BY_PRODUCT_QUERY = `*[
	_type == $recipePageType &&
	__i18n_lang == $locale &&
	references(^.product._ref)
]`;

export const RECIPES_FILTERS = `
(!defined($filters) ||
	(!defined($filters.serves) || count($filters.serves) == 0 || serves in $filters.serves) &&
	(!defined($filters.totalTime) || count($filters.totalTime) == 0 || totalTime in $filters.totalTime) &&
	(!defined($filters.difficulty) || count($filters.difficulty) == 0 || difficulty in $filters.difficulty) &&
	(!defined($filters.cuisine) || count($filters.cuisine) == 0 || cuisine->slug.current in $filters.cuisine) &&
	(!defined($filters.categories) || count($filters.categories) == 0 || count((categories[]->slug.current)[@ in $filters.categories]) > 0)
)
`;

export const RECIPES_BY_FILTERS_QUERY = `
*[
	_type == $type &&
	__i18n_lang == $locale &&
	product->metadata.slug.current == $productSlug &&
	${RECIPES_FILTERS}
]
`;
