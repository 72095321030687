import classNames from 'classnames';

import { SanityImage } from '@components/SanityImage';

import { CmsProductPackshot } from '@interfaces/common';

import styles from './ProductPackshot.module.css';

interface ProductPackshotProps extends CmsProductPackshot {
	className?: string;
	sizes: string;
	style?: React.CSSProperties;
}

export const ProductPackshot: React.VFC<ProductPackshotProps> = ({
	borderRadius,
	className,
	image,
	sizes,
	style,
}) => {
	// This component only returns the packshot image with the border-radius applied.
	// Dimensions should either be set by a parent element or by passing a className.
	const { width, height } = image;
	const aspectRatio = width / height;

	const customProperties = {
		'--product-packshot-border-radius': `${borderRadius}% / ${
			borderRadius * aspectRatio
		}%`,
	} as React.CSSProperties;

	return (
		<SanityImage
			className={classNames(className, styles.image)}
			image={image}
			sizes={sizes}
			style={{ ...customProperties, ...style }}
		/>
	);
};
