import { forwardRef } from 'react';
import classNames from 'classnames';

import { FormLabel } from '@components/Form/FormLabel';

import { FormField } from '@interfaces/form';

import styles from './FormInput.module.css';

export const FormInput = forwardRef<
	HTMLInputElement,
	Omit<FormField, 'validation'>
>(
	(
		{
			label,
			name,
			placeholder,
			required = false,
			type = 'text',
			errors,
			className,
			onChange,
			onBlur,
		},
		ref
	) => {
		let autoComplete = 'on';

		if (type === 'email' || type === 'tel') {
			autoComplete = type;
		}

		return (
			<div
				className={classNames(className, 'form-group stack-block')}
				data-testid="FormInput"
			>
				{label ? (
					<FormLabel htmlFor={name} required={required} value={label} />
				) : null}

				<input
					autoComplete={autoComplete}
					className={classNames(styles.input, 'fluid-font-t5', {
						[styles.hasError]: errors && errors[name],
					})}
					id={name}
					name={name}
					onBlur={onBlur}
					onChange={onChange}
					placeholder={placeholder}
					ref={ref}
					type={type}
				/>

				{errors && errors[name] ? (
					<p className="error fluid-font-t5">{errors[name]?.message}</p>
				) : null}
			</div>
		);
	}
);
