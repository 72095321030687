import { FormCheckbox } from '@components/Form/FormCheckbox';
import { FormCheckboxGroup } from '@components/Form/FormCheckboxGroup';
import { FormInput } from '@components/Form/FormInput';
import { FormSelect } from '@components/Form/FormSelect';
import { FormTextarea } from '@components/Form/FormTextarea';

import { FormField } from '@interfaces/form';

export const emailRegex = /^[\w.!#$%&'*+/=?^`{|}~-]+@[\w.-]+\.[A-Z]{2,}$/i;

export const getFormElement = (type: FormField['type']) => {
	let formElement;

	switch (type) {
		case 'text':
		case 'email':
			formElement = FormInput;
			break;
		case 'select':
			formElement = FormSelect;
			break;
		case 'textarea':
			formElement = FormTextarea;
			break;
		case 'checkbox':
			formElement = FormCheckbox;
			break;
		case 'checkboxgroup':
			formElement = FormCheckboxGroup;
			break;
		default:
			formElement = FormInput;
	}

	return formElement;
};
