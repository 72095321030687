import { ProductLink } from '@components/ProductLink';

import { CmsProductSubnavigation } from '@interfaces/common';

import styles from './ProductSubnavigation.module.css';

interface ProductSubnavigationProps
	extends Pick<CmsProductSubnavigation, 'products'> {
	onNavigate: () => void;
}

export const ProductSubnavigation: React.VFC<ProductSubnavigationProps> = ({
	products,
	onNavigate,
}) => {
	const customProperties = {
		'--product-count': products.length,
		'--justify-content': products.length > 3 ? 'space-between' : 'center',
	} as React.CSSProperties;

	return (
		<ul className={styles.list} style={customProperties}>
			{products.map((product) => (
				<li className={styles.item} key={product._id}>
					<ProductLink {...product} onNavigate={onNavigate} />
				</li>
			))}
		</ul>
	);
};
