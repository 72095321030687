export const cookieNoticeMessages = {
	ariaLabel: {
		id: 'cookieNoticeAriaLabel',
		defaultMessage: 'Cookie consent notice',
	},
	text: {
		id: 'cookieNoticeText',
		defaultMessage:
			'This website uses cookies to ensure you get the best experience on our website.',
	},
	learnMore: {
		id: 'learnMore',
		defaultMessage: 'Learn more about our privacy policy.',
	},
	declineAll: {
		id: 'declineAll',
		defaultMessage: 'Decline All',
	},
	acceptAll: {
		id: 'acceptAll',
		defaultMessage: 'Accept All',
	},
};
