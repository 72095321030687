import { forwardRef, useState } from 'react';
import classNames from 'classnames';

import { FormLabel } from '@components/Form/FormLabel';

import { FormField, FormSelectOption } from '@interfaces/form';

import styles from './FormSelect.module.css';

interface FormSelectProps extends Omit<FormField, 'type' | 'validation'> {
	options?: FormSelectOption[];
}

export const FormSelect = forwardRef<HTMLSelectElement, FormSelectProps>(
	(
		{
			name,
			required,
			label,
			errors,
			placeholder,
			options,
			className,
			onChange,
			onBlur,
		},
		ref
	) => {
		const [selection, setSelection] = useState('');

		return (
			<div
				className={classNames(className, 'form-group stack-block')}
				data-testid="FormSelect"
			>
				{label ? (
					<FormLabel htmlFor={name} required={required} value={label} />
				) : null}

				<select
					className={classNames(styles.select, 'fluid-font-t5', {
						[styles.hasError]: errors && errors[name],
						[styles.placeholder]: selection === '',
					})}
					id={name}
					name={name}
					onBlur={onBlur}
					onChange={(event) => {
						setSelection(event.currentTarget.value);
						onChange(event);
					}}
					ref={ref}
					value={selection}
				>
					<option disabled value="">
						{placeholder}
					</option>

					{options
						? options.map(({ label: title, value }) => (
								<option key={value} label={title} value={value} />
						  ))
						: null}
				</select>

				{errors && errors[name] ? (
					<p className="error fluid-font-t5">{errors[name]?.message}</p>
				) : null}
			</div>
		);
	}
);
