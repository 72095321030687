import Link from 'next/link';
import classNames from 'classnames';

import { ProductPackshot } from '@components/ProductPackshot/ProductPackshot';
import { SanityImage } from '@components/SanityImage';

import {
	CmsProductPackshot,
	CmsProductSubnavigationProduct,
} from '@interfaces/common';
import { breakpoints } from '@utils/device';

import styles from './ProductLink.module.css';

const renderPackshot = ({ image, borderRadius }: CmsProductPackshot) => {
	const { width, height } = image;
	const aspectRatio = width / height;
	const widthMultiplier = aspectRatio < 1 ? aspectRatio : 1;

	const customProperties = {
		'--product-link-packshot-width-multiplier': widthMultiplier,
	} as React.CSSProperties;

	// The numbers below are the `vw` for landscape images. We get the `vw`
	// for portrait images by multiplying with the `widthMultiplier`.
	const sizes = [
		`${breakpoints.lg} ${12 * widthMultiplier}vw`,
		`${breakpoints.md} ${32 * widthMultiplier}vw`,
		`${36 * widthMultiplier}vw`,
	].join(',');

	return (
		<ProductPackshot
			borderRadius={borderRadius}
			className={styles.packshot}
			image={image}
			sizes={sizes}
			style={customProperties}
		/>
	);
};

interface ProductLinkProps extends CmsProductSubnavigationProduct {
	onNavigate: () => void;
}

export const ProductLink: React.VFC<ProductLinkProps> = ({
	slug,
	title,
	shape,
	highlightColor,
	packshot,
	image,
	onNavigate,
}) => {
	const productUrl = `/products/${slug}`;

	const customProperties = {
		'--product-link-highlight-color': highlightColor.hex,
		'--product-link-mask': `url(/svgs/shapes/${shape}.svg)`,
	} as React.CSSProperties;

	return (
		<article className={styles.product} style={customProperties}>
			<figure className={styles.figure}>
				{renderPackshot(packshot)}
				<SanityImage
					className={styles.image}
					image={image}
					options={{
						aspectRatio: 4 / 5,
						fit: 'crop',
					}}
					sizes={`${breakpoints.lg} 16vw, ${breakpoints.md} 42vw, 44vw`}
				/>
			</figure>
			<Link href={productUrl} passHref>
				<a
					className={classNames(styles.link, 'fluid-font-t6-bold', 'breakout')}
					href=""
					onClick={onNavigate}
				>
					{title}
				</a>
			</Link>
		</article>
	);
};
