import { PortableText, PortableTextReactComponents } from '@portabletext/react';

import { FormLabelValue } from '@interfaces/form';
import { linkMarks } from '@utils/serializers';

import styles from './FormLabel.module.css';

const components: Partial<PortableTextReactComponents> = {
	block: {
		normal: ({ children }) => <>{children}</>,
	},
	marks: linkMarks,
};

interface FormLabelProps {
	htmlFor: string;
	required?: boolean;
	value: FormLabelValue;
}

export const FormLabel: React.VFC<FormLabelProps> = ({
	htmlFor,
	required,
	value,
}) => (
	<label
		className={styles['form-label']}
		data-testid="FormLabel"
		htmlFor={htmlFor}
	>
		{Array.isArray(value) ? (
			<PortableText components={components} value={value} />
		) : (
			value
		)}

		{required ? ' *' : null}
	</label>
);
