/* eslint-disable @typescript-eslint/no-var-requires */

const sanityClient = require('./sanity.config');

const primaryDomain = 'www.futurefarm.io';
const primaryDefaultLocale = 'en-US';
const altDomain = 'www.fazendafuturo.io';
const altDefaultLocale = 'pt-BR';
const defaultLocales = [primaryDefaultLocale, altDefaultLocale];
const getActiveLocales = async () => {
	const allLanguages = await sanityClient.fetch(
		`
		*[_type == $type && enabled == true]{
			locale,
			domain
		}
		`,
		{
			type: 'language',
		}
	);
	const primaryActiveLocales = allLanguages
		.filter(({ domain }) => domain === primaryDomain)
		.map(({ locale }) => locale.replace('_', '-'))
		.concat(primaryDefaultLocale);
	const altActiveLocales = allLanguages
		.filter(({ domain }) => domain === altDomain)
		.map(({ locale }) => locale.replace('_', '-'))
		.concat(altDefaultLocale);
	const allActiveLocales = [...primaryActiveLocales, ...altActiveLocales];

	return {
		primaryActiveLocales,
		altActiveLocales,
		allActiveLocales,
	};
};

module.exports = {
	primaryDomain,
	primaryDefaultLocale,
	altDomain,
	altDefaultLocale,
	defaultLocales,
	getActiveLocales,
};
