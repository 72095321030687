import Link from 'next/link';
import classNames from 'classnames';

import { SanityImage } from '@components/SanityImage';

import { CmsAboutSubnavigationPage } from '@interfaces/common';

import styles from './AboutPageLink.module.css';

interface AboutPageLinkProps extends CmsAboutSubnavigationPage {
	onNavigate: () => void;
}

export const AboutPageLink: React.VFC<AboutPageLinkProps> = ({
	slug,
	title,
	subtitle,
	onNavigate,
	image,
}) => {
	const pageUrl = `/about/${slug}`;

	return (
		<article className={styles.page}>
			<figure>
				<SanityImage
					image={image}
					options={{
						fit: 'crop',
						aspectRatio: 3 / 2,
					}}
				/>
			</figure>
			<Link href={pageUrl} passHref>
				<a
					className={classNames(styles.link, 'fluid-font-t6-bold', 'breakout')}
					href=""
					onClick={onNavigate}
				>
					<h2>{title}</h2>
					<p className={styles.subtitle}>{subtitle}</p>
				</a>
			</Link>
		</article>
	);
};
