import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import Link from 'next/link';
import { useRouter } from 'next/router';
import classNames from 'classnames';

import { AboutSubnavigation } from '@components/AboutSubnavigation';
import { Logo } from '@components/Logo';
import { ProductSubnavigation } from '@components/ProductSubnavigation';
import { Svg } from '@components/Svg';

import { navigationMessages } from '@data/ui-messages/navigation';
import { useMediaQuery } from '@hooks/useMediaQuery';
import { CmsMainNavigationItem } from '@interfaces/common';
import { setTabIndexes } from '@utils/a11y';
import { breakpoints } from '@utils/device';
import { neverError } from '@utils/neverError';

import styles from './Navigation.module.css';

const subnavigationTypes = ['productSubnavigation', 'aboutSubnavigation'];

const getSubnavigationToggleId = (key: string) => `subnavigation-toggle-${key}`;
const getSubnavigationMenuId = (key: string) => `subnavigation-menu-${key}`;

interface NavigationProps {
	mainNavigationItems?: CmsMainNavigationItem[];
}

export const Navigation: React.VFC<NavigationProps> = ({
	mainNavigationItems,
}) => {
	const isBreakpointLarge = useMediaQuery(breakpoints.lg);
	const [subnavigationIsExpandedMap, setSubnavigationIsExpandedMap] = useState(
		new Map()
	);

	const router = useRouter();
	const intl = useIntl();
	const subnavigationMenuRefs = useRef<Array<HTMLDivElement | null>>([]);

	useEffect(() => {
		if (mainNavigationItems) {
			const nextSubnavigationIsExpandedMap = mainNavigationItems.reduce(
				(map, { _key, _type }) => {
					return subnavigationTypes.includes(_type)
						? map.set(_key, { isExpanded: false, type: _type })
						: map;
				},
				new Map()
			);

			setSubnavigationIsExpandedMap(nextSubnavigationIsExpandedMap);
		} else {
			setSubnavigationIsExpandedMap(new Map());
		}
	}, [mainNavigationItems]);

	useEffect(() => {
		if (isBreakpointLarge !== null) {
			const nextSubnavigationIsExpandedMap = new Map(
				subnavigationIsExpandedMap
			);

			nextSubnavigationIsExpandedMap.forEach(
				({ type }, subnavigationKey, map) => {
					// Expand product subnavigation by default below large breakpoint
					map.set(subnavigationKey, {
						isExpanded: !isBreakpointLarge && type === 'productSubnavigation',
						type,
					});
				}
			);

			setSubnavigationIsExpandedMap(nextSubnavigationIsExpandedMap);
		}
	}, [isBreakpointLarge]);

	useEffect(() => {
		// Ensure that items are not focusable in the subnavigation when it is hidden
		const observer = new MutationObserver((mutationList) => {
			mutationList.forEach(({ oldValue, target }) => {
				setTabIndexes(target as Element, oldValue === 'true' ? 0 : -1);
			});
		});

		subnavigationMenuRefs.current.forEach((subnavigationMenuRef) => {
			if (subnavigationMenuRef) {
				observer.observe(subnavigationMenuRef, {
					attributeFilter: ['aria-hidden'],
					attributeOldValue: true,
				});

				// Set initial tabindexes
				setTabIndexes(subnavigationMenuRef as Element, -1);
			}
		});

		return () => {
			observer.disconnect();
		};
	}, []);

	const toggleSubnavigation = (subnavigationKey: string) => {
		const nextSubnavigationIsExpandedMap = new Map(subnavigationIsExpandedMap);

		if (nextSubnavigationIsExpandedMap.has(subnavigationKey)) {
			nextSubnavigationIsExpandedMap.forEach(
				({ isExpanded, type }, key, map) => {
					if (key === subnavigationKey) {
						map.set(subnavigationKey, { isExpanded: !isExpanded, type });
					} else if (isBreakpointLarge) {
						map.set(key, { isExpanded: false, type });
					}
				}
			);

			setSubnavigationIsExpandedMap(nextSubnavigationIsExpandedMap);
		}
	};

	const closeSubnavigation = () => {
		const nextSubnavigationIsExpandedMap = new Map(subnavigationIsExpandedMap);

		nextSubnavigationIsExpandedMap.forEach((value, key, map) => {
			// On small screens keep the expanded state and close on bigger screens
			const isExpanded = !isBreakpointLarge ? value.isExpanded : false;

			map.set(key, { ...value, isExpanded });
		});

		setSubnavigationIsExpandedMap(nextSubnavigationIsExpandedMap);
	};

	const getIsActive = (url: string) => router.asPath.includes(url);

	const getSubnavigationNode = (mainNavigationItem: CmsMainNavigationItem) => {
		switch (mainNavigationItem._type) {
			case 'aboutSubnavigation':
				return (
					<AboutSubnavigation
						onNavigate={closeSubnavigation}
						pages={mainNavigationItem.pages}
					/>
				);
			case 'productSubnavigation':
				return (
					<ProductSubnavigation
						onNavigate={closeSubnavigation}
						products={mainNavigationItem.products}
					/>
				);
			default:
				return null;
		}
	};

	const renderSubnavigation = (
		mainNavigationItem: CmsMainNavigationItem,
		index: number
	) => {
		const { _type, _key, title } = mainNavigationItem;
		const subnavigationToggleId = getSubnavigationToggleId(_key);
		const subnavigationMenuId = getSubnavigationMenuId(_key);
		const isExpanded = subnavigationIsExpandedMap.get(_key)?.isExpanded;
		const isAboutSubnavigation = _type === 'aboutSubnavigation';

		const url = isAboutSubnavigation ? '/about' : mainNavigationItem.url;
		const isActive = getIsActive(url);

		const linkClassName = classNames(styles.link, 'fluid-font-t6-bold');
		const subnavigationNode = getSubnavigationNode(mainNavigationItem);

		return (
			<>
				<div
					className={classNames(styles['link-wrapper'], {
						[styles['is-active']]: isActive,
					})}
				>
					{isAboutSubnavigation ? (
						<span className={linkClassName}>{title}</span>
					) : (
						<Link href={url}>
							<a className={linkClassName}>{title}</a>
						</Link>
					)}
					<button
						aria-controls={subnavigationMenuId}
						aria-expanded={isExpanded}
						aria-haspopup="true"
						aria-label={
							isExpanded
								? intl.formatMessage(navigationMessages.closeProductsLabel)
								: intl.formatMessage(navigationMessages.openProductsLabel)
						}
						className={classNames(
							styles.toggle,
							{
								[styles['is-expanded']]: isExpanded,
							},
							'breakout'
						)}
						id={subnavigationToggleId}
						onClick={() => toggleSubnavigation(_key)}
						type="button"
					>
						<Svg className={styles.chevron} id="chevron" />
					</button>
				</div>
				<div
					aria-hidden={!isExpanded}
					className={styles.subnavigation}
					id={subnavigationMenuId}
					ref={(element) => {
						subnavigationMenuRefs.current[index] = element;
					}}
				>
					<div className={styles['subnavigation-content']}>
						{subnavigationNode}
					</div>
				</div>
			</>
		);
	};

	const renderNavigationItem = (
		mainNavigationItem: CmsMainNavigationItem,
		index: number
	) => {
		const { _type, title } = mainNavigationItem;

		switch (_type) {
			case 'aboutSubnavigation':
			case 'productSubnavigation':
				return renderSubnavigation(mainNavigationItem, index);
			case 'internalNavigationLink': {
				const { url } = mainNavigationItem;
				const isActive = getIsActive(url);

				return (
					<div
						className={classNames(styles['link-wrapper'], {
							[styles['is-active']]: isActive,
						})}
					>
						<Link href={url} passHref>
							<a
								className={classNames(styles.link, 'fluid-font-t6-bold')}
								href=""
								onClick={closeSubnavigation}
							>
								{title}
							</a>
						</Link>
					</div>
				);
			}
			case undefined:
				// Newly added navigation types come back as undefined
				// We don’t want to display an error in this case
				return null;
			default: {
				throw neverError('Unknown main navigation item', _type);
			}
		}
	};

	return (
		<nav
			aria-label={intl.formatMessage(navigationMessages.mainNavigationLabel)}
			className={styles.navigation}
		>
			<Logo className={styles.logo} />

			<ul className={styles.list}>
				{mainNavigationItems?.map((mainNavigationItem, index) => {
					return mainNavigationItem._key ? (
						<li className={styles.item} key={mainNavigationItem._key}>
							{renderNavigationItem(mainNavigationItem, index)}
						</li>
					) : null;
				})}
			</ul>
		</nav>
	);
};
