import Link from 'next/link';
import { useRouter } from 'next/router';
import classNames from 'classnames';

import {
	getFullLanguageName,
	getFullRegionName,
	getLocalesByRegion,
	getRegionAndLanguage,
} from '@utils/locale';

import styles from './LanguageSwitcherNav.module.css';

interface LanguageSwitcherNavProps {
	className?: string;
}

export const LanguageSwitcherNav: React.VFC<LanguageSwitcherNavProps> = ({
	className,
}) => {
	const router = useRouter();

	const localesByRegion = getLocalesByRegion(router.locales || []);

	return (
		<nav className={classNames(className, styles.nav, 'stack-block')}>
			{Array.from(localesByRegion).map(([region, locales]) => {
				return (
					<ul key={region}>
						<li>
							<h2 className="fluid-font-t4">
								{getFullRegionName(region, locales)}
							</h2>
							<ul>
								{locales.map((locale: string) => {
									const { language } = getRegionAndLanguage(locale);

									return language ? (
										<li key={locale}>
											<Link href={router.asPath} locale={locale}>
												<a
													className={classNames(
														styles.link,
														'fluid-font-t4-bold'
													)}
												>
													{getFullLanguageName(language)}
												</a>
											</Link>
										</li>
									) : null;
								})}
							</ul>
						</li>
					</ul>
				);
			})}
		</nav>
	);
};
