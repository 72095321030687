import imageUrlBuilder from '@sanity/image-url';
import { ImageUrlBuilderOptionsWithAliases } from '@sanity/image-url/lib/types/types';

import { CmsImage } from '@interfaces/media';

import sanityClient from '../../../sanity.config';

interface SanityImageOptions
	extends Partial<ImageUrlBuilderOptionsWithAliases> {
	// Additional option that can be used to crop within a defined aspect-ratio
	aspectRatio?: number;
}

// https://www.sanity.io/docs/image-urls
const defaultOptions: SanityImageOptions = {
	auto: 'format',
	fit: 'max',
	q: 60,
};

const defaultSrcSetWidths = [400, 800, 1200, 1600, 2000];

const getSrc = (
	image: CmsImage,
	{ aspectRatio, ...options }: SanityImageOptions
) => {
	const builder = imageUrlBuilder(sanityClient);

	if (aspectRatio) {
		options.h = Math.floor(Number(options.w) / aspectRatio);
	}

	return builder
		.withOptions({
			...defaultOptions,
			...options,
		})
		.image(image)
		.url();
};

export const getSrcSet = (
	image: CmsImage,
	widths: number[] = defaultSrcSetWidths,
	options: SanityImageOptions = defaultOptions
) => {
	return widths
		.map((width) => `${getSrc(image, { ...options, w: width })} ${width}w`)
		.join(',');
};

interface SanityImageProps {
	className?: string;
	image: CmsImage;
	loading?: 'eager' | 'lazy';
	sizes?: string;
	srcSetWidths?: number[];
	options?: SanityImageOptions;
	style?: React.CSSProperties;
}

export const SanityImage: React.VFC<SanityImageProps> = ({
	className,
	image,
	loading = 'lazy',
	sizes = '100vw',
	srcSetWidths = defaultSrcSetWidths,
	options = defaultOptions,
	style,
}) => {
	const { alt = '', height, lqip, width } = image;

	return (
		<img
			alt={alt || ''}
			className={className}
			height={height}
			loading={loading}
			sizes={sizes}
			src={lqip}
			srcSet={getSrcSet(image, srcSetWidths, options)}
			style={style}
			width={width}
		/>
	);
};
