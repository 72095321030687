import { useIntl } from 'react-intl';
import Link from 'next/link';

import { cookieNoticeMessages } from '@data/ui-messages/cookie-notice';

import styles from './CookieNotice.module.css';

const buttons = [
	{ message: cookieNoticeMessages.declineAll, value: false },
	{ message: cookieNoticeMessages.acceptAll, value: true },
];

interface CookieNoticeProps {
	setAllowCookies: React.Dispatch<React.SetStateAction<boolean | null>>;
}

export const CookieNotice: React.VFC<CookieNoticeProps> = ({
	setAllowCookies,
}) => {
	const intl = useIntl();
	const handleClick = (selection: boolean) => {
		localStorage.setItem('allowCookies', selection.toString());
		setAllowCookies(selection);
	};

	return (
		<div
			aria-label={intl.formatMessage(cookieNoticeMessages.ariaLabel)}
			className={styles['cookie-notice']}
			role="region"
		>
			<p className="fluid-font">
				{intl.formatMessage(cookieNoticeMessages.text)}
				<br />
				<Link href="/legal/privacy">
					{intl.formatMessage(cookieNoticeMessages.learnMore)}
				</Link>
			</p>
			<div className={styles['btn-container']}>
				{buttons.map(({ message, value }) => (
					<button
						className="fluid-font-t6-bold"
						key={message.id}
						onClick={() => handleClick(value)}
						type="button"
					>
						{intl.formatMessage(message)}
					</button>
				))}
			</div>
		</div>
	);
};
