import { ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';

import styles from './FormResult.module.css';

interface FormResultProps {
	children?: ReactNode;
	message?: string;
	isOpen?: boolean;
	autoClose?: boolean;
	closeDelay?: number;
	className?: string;
	onClose?: () => void;
}

export const FormResult: React.VFC<FormResultProps> = ({
	children,
	className,
	message,
	isOpen = true,
	closeDelay = 8000,
	autoClose = false,
	onClose,
}) => {
	const [open, setOpen] = useState(isOpen);

	useEffect(() => {
		setOpen(isOpen);

		if (isOpen && autoClose) {
			const hideDelay = setTimeout(() => {
				setOpen(false);

				if (onClose) {
					onClose();
				}
			}, closeDelay);

			return () => {
				clearTimeout(hideDelay);
			};
		}
	}, [isOpen]);

	return (
		<div
			className={classNames(className, styles['form-result'], {
				[styles['is-open']]: open,
			})}
			data-testid="FormResult"
		>
			{children || <h2 className="fluid-font-t4-bold">{message}</h2>}
		</div>
	);
};
