import { replaceDashWithUnderscore } from '@utils/locale';

import sanityClient from '../../../sanity.config';

export const sanityFetch = (
	query: string,
	{ locale, ...params }: { [key: string]: any }
) => {
	return sanityClient.fetch(query, {
		...params,
		// In Sanity we use an underscore in the locale.
		// We need to make sure to replace a dash with an underscore.
		...(locale ? { locale: replaceDashWithUnderscore(locale) } : {}),
	});
};
