import { ReactNode, useEffect, useState } from 'react';
import { A11yDialog, A11yDialogClassNames } from 'react-a11y-dialog';
import A11yDialogInstance from 'a11y-dialog';
import classNameJoiner from 'classnames';

import { Svg } from '@components/Svg';

import { useScrollLock } from '@hooks/useScrollLock';
import { setTabIndexes } from '@utils/a11y';

import styles from './Dialog.module.css';

type DialogClassNames = A11yDialogClassNames | 'openButton';

interface DialogProps {
	classNames?: Partial<Record<DialogClassNames, string>>;
	closeButtonLabel: string;
	id: string;
	openButtonContent: ReactNode;
	openButtonLabel?: string;
	title: string;
}

export const Dialog: React.FC<DialogProps> = ({
	children,
	classNames = {},
	closeButtonLabel,
	id,
	openButtonContent,
	openButtonLabel,
	title,
}) => {
	const [dialogInstance, setDialogInstance] = useState<A11yDialogInstance>();
	const { lockScroll, unlockScroll } = useScrollLock();

	useEffect(() => {
		if (!dialogInstance) {
			return;
		}

		const dialogElement = document.getElementById(id);

		if (dialogElement) {
			setTabIndexes(dialogElement, -1);
		}
	}, [dialogInstance]);

	const openDialog = () => dialogInstance?.show();
	const closeDialog = () => dialogInstance?.hide();

	const onShowDialog = (dialogElement: Element) => {
		const links = dialogElement.querySelectorAll('a');

		setTabIndexes(dialogElement, 0);

		links.forEach((link) => {
			link.addEventListener('click', closeDialog);
		});

		lockScroll();
	};

	const onHideDialog = (dialogElement: Element) => {
		setTabIndexes(dialogElement, -1);
		const links = dialogElement.querySelectorAll('a');

		links.forEach((link) => {
			link.removeEventListener('click', closeDialog);
		});

		unlockScroll();
	};

	useEffect(() => {
		if (dialogInstance) {
			dialogInstance.on('show', onShowDialog);
			dialogInstance.on('hide', onHideDialog);

			return () => {
				dialogInstance.off('show', onShowDialog);
				dialogInstance.off('hide', onHideDialog);
			};
		}
	}, [dialogInstance]);

	// Combine passed and local classNames
	const fullA11yDialogClassNames: Partial<
		Record<A11yDialogClassNames, string>
	> = (
		[
			'closeButton',
			'container',
			'dialog',
			'overlay',
			'title',
		] as A11yDialogClassNames[]
	).reduce(
		(accumulator, classNamekey) => ({
			...accumulator,
			[classNamekey]: classNameJoiner(
				classNames[classNamekey],
				styles[classNamekey]
			),
		}),
		{}
	);

	return (
		<>
			<button
				aria-label={openButtonLabel}
				className={classNames?.openButton}
				onClick={openDialog}
				type="button"
			>
				{openButtonContent}
			</button>
			<A11yDialog
				classNames={fullA11yDialogClassNames}
				closeButtonContent={<Svg aria-hidden id="close" />}
				closeButtonLabel={closeButtonLabel}
				closeButtonPosition="last"
				dialogRef={(instance: A11yDialogInstance | undefined) => {
					if (instance) {
						setDialogInstance(instance);
					}
				}}
				id={id}
				title={title}
			>
				{children}
			</A11yDialog>
		</>
	);
};
