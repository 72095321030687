import { useIntl } from 'react-intl';
import Link from 'next/link';
import { useRouter } from 'next/router';
import classNames from 'classnames';

import { LanguageSwitcher } from '@components/LanguageSwitcher';
import { NewsletterSignup } from '@components/NewsletterSignup';
import { Svg } from '@components/Svg';

import { footerMessages } from '@data/ui-messages/footer';
import { navigationMessages } from '@data/ui-messages/navigation';
import {
	CmsNavigationDocument,
	NewsletterSignupTheme,
} from '@interfaces/common';
import { currentYear } from '@utils/date';
import { breakpoints } from '@utils/device';
import { getSocialIconId } from '@utils/social';

import styles from './Footer.module.css';

interface FooterProps {
	navigation?: Pick<CmsNavigationDocument, 'footer' | 'social' | 'legal'>;
	showNewsletter: boolean;
	newsletterTheme?: NewsletterSignupTheme;
}

export const Footer: React.VFC<FooterProps> = ({
	navigation,
	showNewsletter,
	newsletterTheme,
}) => {
	const intl = useIntl();
	const router = useRouter();
	const altLogo = router.locale?.includes('pt-');

	return (
		<footer>
			{showNewsletter ? <NewsletterSignup theme={newsletterTheme} /> : null}

			<div className={classNames(styles.main, 'fluid-font-t6-bold', 'grid')}>
				<div
					className={classNames(styles.logo, { [styles['alt-logo']]: altLogo })}
				>
					<Link href="/" passHref>
						<a
							aria-label={intl.formatMessage(navigationMessages.homeLinkLabel)}
							href="-"
						>
							{altLogo ? (
								<Svg id="fazenda-futuro-logo" />
							) : (
								<Svg id="future-farm-logo" />
							)}
						</a>
					</Link>
				</div>
				<nav aria-label={intl.formatMessage(footerMessages.navigationLabel)}>
					<ul className="stack-block">
						{navigation?.footer.map(({ _key, _type, url, title }) => (
							<li key={_key}>
								{_type === 'internalNavigationLink' ? (
									<Link href={url}>
										<a className={styles.item}>{title}</a>
									</Link>
								) : (
									<a
										className={styles.item}
										href={url}
										rel="nofollower noreferrer"
										target="_blank"
									>
										{title}
									</a>
								)}
							</li>
						))}
					</ul>
				</nav>
				<nav
					aria-label={intl.formatMessage(footerMessages.socialMediaLabel)}
					className={styles['social-links']}
				>
					<ul className="stack-block">
						{navigation?.social.map(({ _key, url, title }) => {
							const iconId = getSocialIconId(url);
							return (
								<li key={_key}>
									<a
										className={classNames(
											styles.item,
											styles.social,
											'stack-inline'
										)}
										href={url}
										rel="noopener noreferrer"
										target="_blank"
									>
										{iconId ? <Svg id={iconId} /> : null}
										<span>{title}</span>
									</a>
								</li>
							);
						})}
					</ul>
				</nav>
				<div className={styles.switcher}>
					<LanguageSwitcher />
				</div>
				<div className={styles.visual}>
					<img
						alt="Future Smiley"
						className={styles.smiley}
						height={232}
						loading="lazy"
						sizes={`${breakpoints.lg} 116px, 96px`}
						src="/img/smiley/smiley.jpg"
						srcSet="/img/smiley/smiley-116.webp 116w, /img/smiley/smiley-96.webp 96w, /img/smiley/smiley-232.webp 232w"
						width={232}
					/>
				</div>
				<div className={styles.copyright}>
					<small
						className={classNames(styles.item, styles.dimmed, 'fluid-font-t7')}
					>
						© {currentYear} {intl.formatMessage(footerMessages.copyright)}
					</small>
				</div>
				<nav
					aria-label={intl.formatMessage(footerMessages.legalNavLabel)}
					className={styles.legal}
				>
					<ul className={classNames(styles['inline-list'], 'stack-inline')}>
						{navigation?.legal.map(({ _key, url, title }) => (
							<li key={_key}>
								<Link href={url}>
									<a
										className={classNames(
											styles.item,
											styles.dimmed,
											'fluid-font-t7'
										)}
									>
										{title}
									</a>
								</Link>
							</li>
						))}
					</ul>
				</nav>
			</div>
		</footer>
	);
};
