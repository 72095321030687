export const navigationMessages = {
	closeProductsLabel: {
		id: 'closeProductNavigation',
		defaultMessage: 'Close products',
	},
	openProductsLabel: {
		id: 'openProductNavigation',
		defaultMessage: 'Open products',
	},
	mainNavigationLabel: {
		id: 'mainNavigationLabel',
		defaultMessage: 'Main navigation',
	},
	closeMainNavigationLabel: {
		id: 'closeMainNavigationLabel',
		defaultMessage: 'Close main navigation label',
	},
	openMainNavigationLabel: {
		id: 'openMainNavigationLabel',
		defaultMessage: 'Open main navigation label',
	},
	homeLinkLabel: {
		id: 'homeLinkLabel',
		defaultMessage: 'Homepage',
	},
};
