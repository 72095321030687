import { RECIPES_FILTERS } from '@data/fragments/recipes';
import {
	CmsFilteredPageDocument,
	CmsRecipeListCard,
	RecipesQueryFilters,
} from '@interfaces/common';

import { sanityFetch } from '../client';
import { getRecipePageSettings } from './recipe-page-settings';

export const getRecipeFilteredPage = async (
	locale: string | undefined,
	filters?: Partial<RecipesQueryFilters> | null
): Promise<CmsFilteredPageDocument> => {
	const { numRecipesToLoad } = await getRecipePageSettings();

	return sanityFetch(
		`
			{
				'recipes': *[_type == $type &&
					__i18n_lang == $locale &&
					${RECIPES_FILTERS}
				] | order(_createdAt desc) [0...$size]
				{
					_createdAt,
					_id,
					'title': metadata.title,
					'slug': metadata.slug.current,
					image {
						_type,
						alt,
						asset,
						'width': asset->metadata.dimensions.width,
						'height': asset->metadata.dimensions.height,
						'lqip': asset->metadata.lqip
					},
					totalTime,
					difficulty,
					'subtitle': product->metadata.title,
				},
				'totalRecipes': count(*[_type == $type && __i18n_lang == $locale && ${RECIPES_FILTERS}])
			}
		`,
		{
			type: 'recipePage',
			size: numRecipesToLoad,
			locale,
			filters,
		}
	);
};

export const getNextFilteredRecipes = async (
	lastCreatedAtDate: string,
	lastId: string,
	locale: string | undefined,
	filters?: Partial<RecipesQueryFilters> | null
): Promise<Required<CmsRecipeListCard>[]> => {
	const { numRecipesToLoad } = await getRecipePageSettings();

	return sanityFetch(
		`
			*[
				_type == $type &&
				__i18n_lang == $locale &&
				${RECIPES_FILTERS} &&
				(_createdAt < $lastCreatedAtDate || (_createdAt < $lastCreatedAtDate && _id < $lastId))
			] | order(_createdAt desc) [0...$size]
			{
				_createdAt,
				_id,
				'title': metadata.title,
				'slug': metadata.slug.current,
				image {
					_type,
					alt,
					asset,
					'width': asset->metadata.dimensions.width,
					'height': asset->metadata.dimensions.height,
					'lqip': asset->metadata.lqip
				},
				totalTime,
				difficulty,
				'subtitle': product->metadata.title,
			}
		`,
		{
			type: 'recipePage',
			lastCreatedAtDate,
			lastId,
			size: numRecipesToLoad,
			locale,
			filters,
		}
	);
};
