import { useIntl } from 'react-intl';
import Link from 'next/link';
import { useRouter } from 'next/router';
import classNames from 'classnames';

import { Svg } from '@components/Svg';

import { navigationMessages } from '@data/ui-messages/navigation';

import styles from './Logo.module.css';

interface LogoProps {
	className?: string;
}

export const Logo: React.VFC<LogoProps> = ({ className = '' }) => {
	const router = useRouter();
	const isAltLogo = router.locale?.includes('pt-');
	const intl = useIntl();

	return (
		<Link href="/" passHref>
			<a
				aria-label={intl.formatMessage(navigationMessages.homeLinkLabel)}
				className={classNames(className, styles.logo, {
					[styles['alt-logo']]: isAltLogo,
				})}
				data-testid="Logo"
			>
				<Svg id={isAltLogo ? 'fazenda-futuro-logo' : 'future-farm-logo'} />
			</a>
		</Link>
	);
};
